import React from "react"
import Layout from "../components/layout"
import Content from "../components/cx/cx"

export default function cx() {
  return (
    <Layout>
      <Content />
    </Layout>
  )
}
