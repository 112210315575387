import React from "react"
import Section from "../global/Section"
import Banner from "../../images/cx/banner.png"
import Banner2 from "../../images/cx/banner2.png"
import iosBtn from "../../images/cx/ios.png"

import appIntro1 from "../../images/cx/appInto01.png"
import appIntro2 from "../../images/cx/appInto02.png"
import appIntro3 from "../../images/cx/appInto03.png"
import appIntro4 from "../../images/cx/appInto04.png"
import appIntro5 from "../../images/cx/appInto05.png"
import appIntro6 from "../../images/cx/appInto06.png"
import appIntro7 from "../../images/cx/appInto07.png"

import appDemo1 from "../../images/cx/appDemo01.png"
import appDemo2 from "../../images/cx/appDemo02.png"
import appDemo3 from "../../images/cx/appDemo03.png"
import appDemo4 from "../../images/cx/appDemo04.png"

import portal from "../../images/cx/portal.png"

export default function cx() {
  return (
    <>
      <Section extraMargin={"8rem"}>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="font-w-extrabold">CelerX</h2>
            <p>
              CelerX is an online mobile eSports platform where players can
              compete against other players for real money or crypto.
            </p>
            <img src={Banner} width="100%" className="pt-5" alt="img" />
          </div>
        </div>
      </Section>
      <Section>
        <div className="row">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">Background</h4>
            <p className="d-grey pt-5">
              CelerX is an online mobile eSports platform composed of three
              major modules, the platform itself, CelerX Game Developer Portal,
              and the games. CelerX empowers a normal game into a real
              money/crypto game. Beyond that, it provides a holistic game
              features such as loyalty, social feed, league, and tournaments.
            </p>
          </div>
        </div>
      </Section>
      <Section>
        <div className="row">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">CelerX Games</h4>
            <p className="d-grey pt-5">
              There are two major kinds of CelerX Games. One is the game
              platform, and the other one is the single title game. The game
              platform aggregates games in one app while a single title game
              only focuses on one. CelerX provides multiple features for
              different objectives such as retention, ARPPU, player engagement
              time, and more. Both types of games share the same features such
              as head-to-head match, tournament, league, loyalty, etc. Check out
              the up-to-date products on the app store or our website to have
              more details about the product.
            </p>
          </div>
        </div>
      </Section>
      <Section>
        <div
          className="row justify-content-center pt-5"
          style={{
            background:
              "linear-gradient(88.4deg,rgba(247, 249, 255, 0.4) 4.22%,rgba(227, 235, 255, 0.4) 35.74%,rgba(245, 207, 226, 0.4) 71.03%,rgba(251, 249, 250, 0.4) 94.55%)",
            borderRadius: "2rem",
            filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));",
          }}
        >
          <div className="col-lg-8 col-md-8 col-sm-12">
            <h6 className="font-w-extrabold text-center mb-3">
              Learn more on App Store
            </h6>
            <a href="https://apps.apple.com/us/developer/celer-network/id1459895767">
              <img
                src={iosBtn}
                width="160px"
                className="d-block mx-auto"
                alt="img"
              />
            </a>
          </div>
          <div className="col-sm-12 mt-5 p-0">
            <img src={Banner2} width="100%" alt="img" />
          </div>
        </div>
      </Section>
      <Section>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">Play with USD / Crypto</h4>
            <p className="d-grey pt-5">
              As a pioneer of blockchain layer 2 technology, CelerX games enable
              the user to use either USD or crypto to enter the real money
              match.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img
              src={appIntro1}
              width="100%"
              className="imgSize-shadowStyle"
              alt="img"
            />
            <p className="l-grey pt-4 text-center">
              Players can play RMG either in fiat or crypto.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img
              src={appIntro2}
              width="100%"
              className="imgSize-shadowStyle"
              alt="img"
            />
            <p className="l-grey pt-4 text-center">
              Swithcing fiat and crypto mode.
            </p>
          </div>
        </div>
      </Section>
      <Section>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">Multiple Ways of Competition</h4>
            <p className="d-grey pt-5">
              There are many ways of competition in CelerX. For the 1v1 match,
              the user competes against similar skill players to win the prize.
              For the tournament, multiple players compete for their score and
              get the prize accordingly. As for League, it lists the winners who
              have won the highest prizes in a period, and the winners can get
              the prize accordingly.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img
              src={appIntro3}
              width="100%"
              className="imgSize-shadowStyle"
              alt="img"
            />
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img src={appDemo1} width="100%" alt="img" />
            <p className="l-grey pt-2 text-center">
              1v1 Match User Flow: Pick Match, Matching, Game Play, Match
              Result.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img src={appDemo2} width="100%" alt="img" />
            <p className="l-grey pt-2 text-center">
              Tournament flow: Tournament page, Game play, Tournament Result.
            </p>
          </div>
        </div>
      </Section>
      <Section>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">Payment: Deposit and Withdraw</h4>
            <p className="d-grey pt-5">
              Payment is one of our core features, which allow players to
              deposit money into the game and withdraw their winnings. For
              deposit, we offer different channels such as Credit card, Apple
              Pay, and PayPal. For withdraw, players can withdraw their winnings
              back to their bank account or their PayPal account.
            </p>
          </div>

          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img src={appDemo3} width="100%" alt="img" />
            <p className="l-grey pt-2 text-center">
              Deposit flow: Money page, Age check, Deposit page, Get money
              animation.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img src={appDemo4} width="100%" alt="img" />
            <p className="l-grey pt-2 text-center">
              Withdraw flow: Account, Withdraw, Enter PayPal email, Withdraw
              confirmation, Withdraw result
            </p>
          </div>
        </div>
      </Section>
      <Section>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">
              Live Feeds and Social Challenge
            </h4>
            <p className="d-grey pt-5">
              Live Feeds and Social Challenge are the social features in the
              app. Players can see how much a player earns and what games other
              players are playing. A player can initiate a challenge to another
              player who decides to accept or decline the challenge. Live Feeds
              also serve as an important cross-promotion feature in the app. It
              shows all CelerX games in the feeds, so the players in one game
              can see other players are playing and winning in the other game.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img
              src={appIntro4}
              width="100%"
              className="imgSize-shadowStyle"
              alt="img"
            />
            <p className="l-grey pt-2 text-center">
              Live Feeds: showing what games is hot and winnings from top
              players.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img
              src={appIntro5}
              width="100%"
              className="imgSize-shadowStyle"
              alt="img"
            />
            <p className="l-grey pt-2 text-center">
              Social Challenge: Initiate challenges to other players or friends.
            </p>
          </div>
        </div>
      </Section>
      <Section>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">Loyalty Programs</h4>
            <p className="d-grey pt-5">
              Loyalty programs are the most important features to boost game
              retention. It includes Daily check-in, Daily Tasks, Tiers, and so
              much more. Gems are the third token in the game. Users can get
              them by playing games, daily check-in, and complete daily tasks.
              And, users can exchange physical gifts or bonus cash with Gems.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img
              src={appIntro6}
              width="100%"
              className="imgSize-shadowStyle"
              alt="img"
            />
            <p className="l-grey pt-2 text-center">
              Loyalty Programs: boost retention, average time playing, and other
              KPIs.
            </p>
          </div>
        </div>
      </Section>
      <Section>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">CelerX Games Portal</h4>
            <p className="d-grey pt-5">
              The Portal helps game developers or publishers to submit their
              games to our platform. Through the Portal, game developers can
              transform their game into a real money game(RMG) with just simple
              steps of integration. Besides submitting the game to the platform,
              it also serves as a tool of Live-Ops, analytics, and more.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img src={portal} width="100%" alt="img" />
          </div>
          <div className="col-sm-12 pt-5">
            <h6 className="font-w-extrabolditalic d-grey text-center">
              Check out the CelerX Game Portal in the link below
            </h6>
            <p className="text-center pt-5">
              <a
                href="https://portal.celerx.app/#/user/login"
                className="btn-main font-w-bold"
              >
                CelerX Games Portal
              </a>
            </p>
          </div>
        </div>
      </Section>
      <Section>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">
              Modularized and Configurable Components
            </h4>
            <p className="d-grey pt-5">
              All the components in the game are configurable. Game developers
              or publishers can adjust parameters for regular testing or
              live-ops based on their campaign objectives, KPI goals, ROAS, etc
              in the CelerX Games Portal.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img src={appIntro7} width="100%" alt="img" />
          </div>
        </div>
      </Section>
    </>
  )
}
